import React, { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Image, PropsWithClassProps, Link } from '@vgn-medien-holding/vgn-fe-components';
import { format } from 'date-fns/format';
import { isToday } from 'date-fns/isToday';
import { twMerge } from 'tailwind-merge';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { ShowtimeProgress } from '@components/molecules/ShowtimeProgress/ShowtimeProgress';
import { createDate } from '@src/utils/dateHelpers';
import { getChannelLogo } from '@src/utils/sortedChannels';
import { GetChannelShowtimeNowDocument, TvChannel, TvChannelShowtime } from '@lib/graphql/generated';
import { useQuery } from '@lib/graphql/urql';

export interface TvCurrentProgramProps extends PropsWithClassProps {
  channel?: TvChannel & { showtimeData: TvChannelShowtime };
  channelEntry?: TvChannelShowtime;
  primetime?: boolean;
  showInitially?: boolean;
  showDate?: boolean;
}

export const TvCurrentProgram = ({
  channel,
  channelEntry,
  showInitially,
  classProps,
  primetime = false,
  showDate,
}: TvCurrentProgramProps) => {
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '50px 350px',
  });

  const pause = !!channel?.showtimeData?.id || !inView || !!channelEntry;
  const [showtimeNow, setShowtimeNow] = useState(undefined);

  const [{ fetching: showtimeLoading, data: channelData, stale }, refetch] = useQuery({
    query: GetChannelShowtimeNowDocument,
    variables: { id: channel?.id, primetime },
    pause,
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const showtime = channel?.showtimeData || channelData?.channelShowtimeNow || channelEntry;
    if (!showtimeLoading && channelData) {
      setShowtimeNow(channel?.showtimeData || channelData?.channelShowtimeNow);
    } else if (channelEntry) {
      const startTime = showtimeNow?.start?.slice(11, 16);
      const endTime = showtimeNow?.stop?.slice(11, 16);
      setShowtimeNow({ ...showtime, start_time: startTime, stop_time: endTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, channelData, showtimeLoading, channelEntry]);

  const baseStyle = twMerge(
    'block w-min min-w-72 h-full max-w-full cursor-pointer border border-transparent hover:border-primary rounded-lg relative transition-colors duration-300',
    classProps?.root,
  );

  const channelLogo = useMemo(() => {
    return getChannelLogo(channel?.id);
  }, [channel?.id]);
  const itemVisible = useMemo(() => inView || showInitially, [inView, showInitially]);

  return (
    <Link href={`/programm/${showtimeNow?.event_id}`} classProps={{ root: baseStyle }} ref={inViewRef}>
      {itemVisible && (
        <>
          <div className="aspect-video w-full">
            <div className="relative size-full overflow-hidden rounded-lg rounded-b-none">
              {showtimeNow?.image?.file?.path ? (
                <Image src={showtimeNow?.image.file.path} alt={showtimeNow?.title} fill sizes="480px" copyright="" />
              ) : (
                <FallbackCard />
              )}
            </div>
          </div>
          <ShowtimeProgress
            showtime={showtimeNow}
            onShowEnded={() => {
              if (!showtimeLoading && !pause && !stale && !primetime) {
                refetch();
              }
            }}
          />
          <div className="mt-1 gap-3 p-1">
            {showtimeNow?.start && showtimeNow?.stop && (
              <div className="text-sm text-gray-400">
                {showDate &&
                  (isToday(createDate(showtimeNow?.start))
                    ? 'Heute'
                    : format(createDate(showtimeNow?.start), 'dd.MM')) + ', '}
                {format(createDate(showtimeNow?.start), 'HH:mm')}&nbsp;-&nbsp;
                {format(createDate(showtimeNow?.stop), 'HH:mm')} Uhr
              </div>
            )}
            <div className="font-herokid font-medium">{showtimeNow?.title}</div>
          </div>
          {channelLogo && (
            <div className="absolute right-3 top-2 h-5 w-8 rounded bg-gray-820/50 p-1">
              <div className="relative size-full">
                <Image
                  src={channelLogo}
                  alt={channel?.name || channelEntry?.channel?.name}
                  priority={true}
                  placeholder="empty"
                  width={48}
                  height={24}
                  copyright=""
                  classProps={{ root: 'h-auto max-h-full w-12 object-fit', container: 'flex items-center' }}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Link>
  );
};
